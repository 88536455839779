<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">AIQ Personas</li>
          </ol>
        </nav>
      </div>
      <h6 class="mb-0 text-uppercase">AIQ Personas List</h6>
      <hr />
      <div class="card">
        <div class="aiq-persons-list">
          <div style="display:flex; align-items:center">
            <span class="m-1">Show</span>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="limit"
              @change="handleShowRow"
            >
              <option :value="10" selected>10</option>
              <option :value="25">25</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
              <option :value="500">500</option>
              <option :value="1000">1000</option>
              <option :value="1500">1500</option>
              <option :value="2000">2000</option>
            </select>
            <span class="m-1">Entries</span>
          </div>
          <div class="search-aiq">
            <input type="text" class="form-control" placeholder="Search" v-model="searchKey" />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Platform</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ offset + (index + 1) }}</td>
                  <td>{{ data.firstName }}</td>
                  <td>{{ data.lastName }}</td>
                  <td>{{ data.mobilePhone }}</td>
                  <td>{{ data.platform }}</td>
                  <td>
                    <div class="d-flex order-actions justify-content-center">
                      <a
                        href="javascript:void(0);"
                        v-on:click="singlePersonas(data.contactID)"
                        data-bs-toggle="modal"
                        data-bs-target="#detailsPersonasModal"
                        class="edit-button"
                      >
                        <i class="fa fa-eye text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Sl</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Platform</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
            Showing {{showingFrom}} to {{showingTo}} of {{totalDataCount}} entries
          </div>
          <nav
            class="page-navigation justify-content-center d-flex m-3 mb-5"
            aria-label="page-navigation"
            v-if="dataList.length>0"
          >
            <v-pagination
              v-model="page"
              :pages="totalPage"
              :range-size="1"
              active-color="#0d6efd"
              @update:modelValue="handlePaginate"
              style="gap:15px"
            ></v-pagination>
          </nav>
        </div>
      </div>
    </div>
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="detailsPersonasModal"
      tabindex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <form>
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">
                <i class="fa fa-eye"></i> Details AIQ Personas
              </h5>
              <button
                type="button"
                id="addModalCloseButton"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="row ms-1">
              <div class="modal-body col-md-8">
                <table>
                  <tr>
                    <td>First Name:</td>
                    <td>{{ singleData.firstName }}</td>
                  </tr>
                  <tr>
                    <td>Last Name:</td>
                    <td>{{ singleData.lastName }}</td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>{{ singleData.email ? singleData.email : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td>Platform:</td>
                    <td>{{ singleData.platform }}</td>
                  </tr>
                </table>
              </div>
              <div class="modal-body col-md-6">
                <table>
                  <tr>
                    <td>
                      <strong>Loyalty Status:</strong>
                    </td>
                    <td>{{ singleData.loyalty ? "Yes" : "No" }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                <i class="fadeIn animated bx bx-x"></i>Close
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Add Modal -->
  </div>
</template>

<script src='../js/aiq-personas.js'></script>

<style scoped>
@import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
.metaModal {
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.metaModal:hover {
  cursor: pointer;
  color: #09f;
}

.Page-active {
  color: #fff !important;
}

.Page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin: 0 2px;
  color: #666666;
  background-color: transparent;
  font-size: 14px;
  border-radius: 3px;
  box-sizing: border-box;
  border-color: transparent;
  cursor: pointer;
  outline: 0;
  user-select: none;
}

.aiq-persons-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.aiq-persons-list:first-child {
  margin-left: 16px;
}
.aiq-persons-list .search-aiq {
  margin-right: 16px;
}

@media screen and (max-width: 576px) {
  .aiq-persons-list {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: start;
  }
}
</style>
